.center_city {
    height: 85vh;
    position: relative;
}

.center_city_content {
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.button_city {
    margin-top: 15px;
    margin-bottom: 30px;
}
